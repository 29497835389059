<template>
  <div>
    <img src="https://jeffjames.s3.us-west-2.amazonaws.com/sake.jpg" border="0" alt="Jeff James" />
  </div>
  <div>
    <h2><a href="http://cedarriverbrewing.com" target="_blank">Cedar River Brewing Company</a></h2>
  </div>
  <div>
    <h2><a href="https://sakenishi.wordpress.com" target="_blank">Sake Nishi</a></h2>
  </div>
</template>
