<template>
    <div>
        <img src="https://jeffjames.s3.us-west-2.amazonaws.com/chambers201205.jpg" border="0" alt="Golf" />
    </div>
    <h1>Golf</h1>
    <div id="golf">
        <p align="center"><font size="4"><strong>FDJ Invitational Golf Tournament</strong></font></p>
        <p align="center">Tournament History</p><br>
        <table class="center">
            <tr>
                <td class="center">
                    <p><u>30th, Jefferson Park Golf Course, Friday November 29, 2024</u></p>
                    <p>Low Net Results</p>
                    <table cellpadding="0" cellspacing="0">
                        <NetScore player="Ed Phillips" score="98" handicap="34" winner="true" />
                        <NetScore player="Bob Phillips" score="109" handicap="37" />
                        <NetScore player="Jeff James" score="108" handicap="36" />
                        <NetScore player="Andy Neyens" score="109" handicap="30" />
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <GrossScore player="Ed Phillips" front="51" back="47" winner="true" />
                        <GrossScore player="Jeff James" front="59" back="49" />
                        <GrossScore player="Andy Neyens" front="61" back="48" />
                        <GrossScore player="Bob Phillips" front="55" back="54" />
                    </table>
                    <p class="notes">
                        Overcast and cold, but dry and  not freezing.  Started about 11:15
                        and finished before dusk, about 4:15.  Lots of people playing!
                    </p>
                    <br /><br />
                    <p><u>29th, Bellevue Golf Course, Friday November 24, 2023</u></p>
                    <p>Low Net Results</p>
                    <table cellpadding="0" cellspacing="0">
                        <NetScore player="Ethan Phillips" score="95" handicap="41" winner="true" />
                        <NetScore player="John Phillips" score="100" handicap="36" />
                        <NetScore player="Jeff James" score="104" handicap="38" />
                        <NetScore player="Ed Phillips" score="107" handicap="31" />
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <GrossScore player="Ethan Phillips" front="45" back="50" winner="true" />
                        <GrossScore player="John Phillips" front="46" back="54" />
                        <GrossScore player="Andy Neyens" front="50" back="51" />
                        <GrossScore player="Jeff James" front="54" back="50" />
                        <GrossScore player="Ed Phillips" front="58" back="49" />
                    </table>
                    <p class="notes">
                        Sunny but cold.  Frosty in the shade, pleasant in the sun.
                        Mostly temporary greens on the front nine, but many of the
                        permanent greens were open on the back.
                    </p>
                    <br /><br />
                    <p><u>28th, Jefferson Park Golf Course, Friday November 25, 2022</u></p>
                    <p>Low Net Results</p>
                    <table cellpadding="0" cellspacing="0">
                        <NetScore player="Ed Phillips" score="98" handicap="33" winner="true" />
                        <NetScore player="Bob Phillips" score="108" handicap="37" />
                        <NetScore player="John Phillips" score="107" handicap="32" />
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <GrossScore player="Ed Phillips" front="52" back="46" winner="true" />
                        <GrossScore player="John Phillips" front="56" back="51" />
                        <GrossScore player="Bob Phillips" front="55" back="53" />
                        <GrossScore player="Jeff James" front="55" />
                        <GrossScore player="Andy Neyens" front="59" />
                    </table>
                    <p class="notes">
                        Rainy, breezy, and cold. Only one other group on the course.
                        Most of us surrendered to the weather somewhere along the way,
                        but Bob, Ed, and John soldiered on through the full eighteen.
                    </p>
                    <br /><br />
                    <p><u>27th, Jackson Park Golf Course, Friday November 26, 2021</u></p>
                    <p>Low Net Results</p>
                    <table cellpadding="0" cellspacing="0">
                        <NetScore player="Bob Phillips" score="109" handicap="39" winner="true" />
                        <NetScore player="Ed Phillips" score="110" handicap="29" />
                        <NetScore player="Jeff James" score="115" handicap="34" />
                        <NetScore player="John Phillips" score="110" handicap="26" />
                        <NetScore player="Keith James" score="136" handicap="44" />
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <GrossScore player="Bob Phillips" front="54" back="55" winner="true" />
                        <GrossScore player="Ed Phillips" front="55" back="55" />
                        <GrossScore player="John Phillips" front="61" back="49" />
                        <GrossScore player="Jeff James" front="61" back="54" />
                        <GrossScore player="Keith James" front="68" back="68" />
                    </table>
                    <p class="notes">Light drizzle for most of the round.</p>
                    <br /><br />
                    <p><u>26th, Bellevue Golf Course, Friday November 29, 2019</u></p>
                    <p>Low Net Results</p>
                    <table cellpadding="0" cellspacing="0">
                        <NetScore player="Keith James" score="101" handicap="57" winner="true" />
                        <NetScore player="Ed Phillips" score="88" handicap="39" />
                        <NetScore player="Bob Phillips" score="98" handicap="49" />
                        <NetScore player="Cassie Phillips" score="97" handicap="44" />
                        <NetScore player="Jeff James" score="98" handicap="41" />
                        <NetScore player="Ethan Phillips" score="108" handicap="46" />
                        <NetScore player="John Phillips" score="94" handicap="27" />
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <GrossScore player="Ed Phillips" front="46" back="42" winner="true" />
                        <GrossScore player="John Phillips" front="54" back="40" />
                        <GrossScore player="Cassie Phillips" front="43" back="54" />
                        <GrossScore player="Bob Phillips" front="49" back="49" />
                        <GrossScore player="Jeff James" front="51" back="47" />
                        <GrossScore player="Keith James" front="48" back="53" />
                        <GrossScore player="Ethan Phillips" front="53" back="55" />
                    </table>
                    <p class="notes">Sunny but very cold. Temporary greens. Frozen fairways for much of the round.</p>
                    <br /><br />
                    <p><u>25th, Jefferson Park Golf Course, Friday November 23, 2018</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Keith James</b></td><td class="score"><b>127</b></td><td class="score"><b>-59</b></td><td class="score"><b>68</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>112</td><td>-40</td><td>72</td></tr>
                        <tr><td class="name">Ethan Phillips</td><td>121</td><td>-44</td><td>77</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>123</td><td>-44</td><td>79</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>56</b></td><td class="score"><b>56</b></td><td class="score"><b>112</b></td></tr>
                        <tr><td class="name">Ethan Phillips</td><td>60</td><td>61</td><td>121</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>62</td><td>61</td><td>123</td></tr>
                        <tr><td class="name">Keith James</td><td>64</td><td>63</td><td>127</td></tr>
                    </table>
                    <p class="notes">Forecast called for rain, but it was dry except for the 17th hole. Very few other groups on the course. Overall a very pleasant round.</p>
                    <br /><br />
                    <p><u>24th, Jackson Park Golf Course, Friday November 24, 2017</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Ethan Phillips</b></td><td class="score"><b>104</b></td><td class="score"><b>-50</b></td><td class="score"><b>64</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>98</td><td>-32</td><td>66</td></tr>
                        <tr><td class="name">Jeff James</td><td>112</td><td>-41</td><td>71</td></tr>
                        <tr><td class="name">Keith James</td><td>132</td><td>-56</td><td>76</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>112</td><td>-35</td><td>77</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>121</td><td>-37</td><td>84</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>49</b></td><td class="score"><b>49</b></td><td class="score"><b>98</b></td></tr>
                        <tr><td class="name">Ethan Phillips</td><td>54</td><td>50</td><td>104</td></tr>
                        <tr><td class="name">Jeff James</td><td>58</td><td>54</td><td>112</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>58</td><td>54</td><td>112</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>60</td><td>61</td><td>121</td></tr>
                        <tr><td class="name">Keith James</td><td>70</td><td>62</td><td>132</td></tr>
                    </table>
                    <p class="notes">Cloudy, rain on the 8th and 9th hole, otherwise dry.</p>
                    <br /><br />
                    <p><u>23rd, High Cedars Golf Club, Friday November 25, 2016</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>108</b></td><td class="score"><b>-45</b></td><td class="score"><b>63</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>100</td><td>-36</td><td>64</td></tr>
                        <tr><td class="name">Keith James</td><td>122</td><td>-58</td><td>64</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>109</td><td>-40</td><td>69</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>111</td><td>-32</td><td>79</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>122</td><td>-38</td><td>84</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>50</b></td><td class="score"><b>50</b></td><td class="score"><b>100</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>54</td><td>54</td><td>108</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>60</td><td>49</td><td>109</td></tr>
                        <tr><td class="name">Ed Phillips</td><td></td><td></td><td>111</td></tr>
                        <tr><td class="name">Keith James</td><td>59</td><td>63</td><td>122</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td></td><td></td><td>122</td></tr>
                    </table>
                    <p class="notes">Raining when we got there, but let up just as we started.  The course was soggy but the weather was dry for our round.  Very nice course.</p>
                    <br /><br />
                    <p><u>22nd, Maplewood Golf Course, Friday November 27, 2015</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>96</b></td><td class="score"><b>-44</b></td><td class="score"><b>52</b></td></tr>
                        <tr><td class="name">Bob Phillips</td><td>109</td><td>-43</td><td>56</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>102</td><td>-38</td><td>64</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>111</td><td>-41</td><td>70</td></tr>
                        <tr><td class="name">Jeff James</td><td>116</td><td>-42</td><td>74</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>48</b></td><td class="score"><b>48</b></td><td class="score"><b>96</b></td></tr>
                        <tr><td class="name">Ed Phillips</td><td>50</td><td>52</td><td>102</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>52</td><td>57</td><td>109</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>52</td><td>59</td><td>111</td></tr>
                        <tr><td class="name">Jeff James</td><td>60</td><td>56</td><td>116</td></tr>
                    </table>
                    <p class="notes">Beautiful sunny day, but started off well below freezing.  Temporary greens.</p>
                    <br /><br />
                    <p><u>21st, Jackson Park Golf Course, Friday November 28, 2014</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Ed Phillips</b></td><td class="score"><b>100</b></td><td class="score"><b>-46</b></td><td class="score"><b>54</b></td></tr>
                        <tr><td class="name">Bob Phillips</td><td>105</td><td>-49</td><td>56</td></tr>
                        <tr><td class="name">Keith James</td><td>132</td><td>-51</td><td>81</td></tr>
                        <tr><td class="name">Jeff James</td><td>118</td><td>-36</td><td>82</td></tr>
                        <tr><td class="name">Ethan Phillips</td><td>128</td><td>-42</td><td>86</td></tr>
                        <tr><td class="name">John Phillips</td><td>129</td><td>-29</td><td>100</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Ed Phillips</b></td><td class="score"><b>53</b></td><td class="score"><b>47</b></td><td class="score"><b>100</b></td></tr>
                        <tr><td class="name">Bob Phillips</td><td>57</td><td>48</td><td>105</td></tr>
                        <tr><td class="name">Jeff James</td><td>61</td><td>57</td><td>118</td></tr>
                        <tr><td class="name">Ethan Phillips</td><td>68</td><td>60</td><td>128</td></tr>
                        <tr><td class="name">John Phillips</td><td>64</td><td>65</td><td>129</td></tr>
                        <tr><td class="name">Keith James</td><td>63</td><td>69</td><td>132</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>65</td><td></td><td></td></tr>
                    </table>
                    <p class="notes">Rained all day, with a few moments of mixed rain and snow.  Temperature was moderate to start, but dropped to near freezing by the end of the round.</p>
                    <br /><br />
                    <p><u>20th, Jackson Park Golf Course, Friday November 29, 2013</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Ed Phillips</b></td><td class="score"><b>110</b></td><td class="score"><b>-48</b></td><td class="score"><b>62</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>97</td><td>-32</td><td>65</td></tr>
                        <tr><td class="name">Keith James</td><td>126</td><td>-45</td><td>81</td></tr>
                        <tr><td class="name">Jeff James</td><td>111</td><td>-30</td><td>81</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>127</td><td>-44</td><td>83</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>50</b></td><td class="score"><b>47</b></td><td class="score"><b>97</b></td></tr>
                        <tr><td class="name">Ed Phillips</td><td>60</td><td>50</td><td>110</td></tr>
                        <tr><td class="name">Jeff James</td><td>60</td><td>51</td><td>111</td></tr>
                        <tr><td class="name">Keith James</td><td>59</td><td>67</td><td>126</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>65</td><td>62</td><td>127</td></tr>
                    </table>
                    <br /><br />
                    <p><u>19th, Meadow Park Golf Course, Friday November 23, 2012</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Cassie Phillips</b></td><td class="score"><b>105</b></td><td class="score"><b>-50</b></td><td class="score"><b>55</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>98</td><td>-34</td><td>64</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>112</td><td>-47</td><td>65</td></tr>
                        <tr><td class="name">John Phillips</td><td>101</td><td>-35</td><td>66</td></tr>
                        <tr><td class="name">Keith James</td><td>119</td><td>-42</td><td>77</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>127</td><td>-40</td><td>87</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>44</b></td><td class="score"><b>54</b></td><td class="score"><b>98</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>55</td><td>46</td><td>101</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>50</td><td>55</td><td>105</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>59</td><td>53</td><td>112</td></tr>
                        <tr><td class="name">Keith James</td><td>61</td><td>58</td><td>119</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>66</td><td>61</td><td>127</td></tr>
                    </table>
                    <p class="notes">Rained off and on during the front nine, rained hard most of the back nine.  Temperature was moderate, helping make the rain more bearable.</p>
                    <br /><br />
                    <p><u>18th, Auburn Golf Course, Friday November 25, 2011</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Bob Phillips</b></td><td class="score"><b>114</b></td><td class="score"><b>-49</b></td><td class="score"><b>65</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>103</td><td>-37</td><td>66</td></tr>
                        <tr><td class="name">Keith James</td><td>113</td><td>-42</td><td>71</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>100</td><td>-27</td><td>73</td></tr>
                        <tr><td class="name">Jeff James</td><td>108</td><td>-32</td><td>76</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>114</td><td>-37</td><td>77</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Mark Plummer</b></td><td class="score"><b>51</b></td><td class="score"><b>49</b></td><td class="score"><b>100</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>54</td><td>49</td><td>103</td></tr>
                        <tr><td class="name">Jeff James</td><td>50</td><td>58</td><td>108</td></tr>
                        <tr><td class="name">Keith James</td><td>55</td><td>58</td><td>113</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>57</td><td>57</td><td>114</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>55</td><td>59</td><td>114</td></tr>
                    </table>
                    <br /><br />
                    <p><u>17th, Jefferson Park Golf Course, Friday November 26, 2010</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>96</b></td><td class="score"><b>-38</b></td><td class="score"><b>58</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td>91</td><td>-32</td><td>59</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>110</td><td>-37</td><td>73</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Mark Plummer</b></td><td class="score"><b>47</b></td><td class="score"><b>44</b></td><td class="score"><b>91</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>50</td><td>46</td><td>96</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>58</td><td>52</td><td>110</td></tr>
                    </table>
                    <p class="notes">Snow closed Auburn golf course, where we originally planned to play, as well as many other course in the area.  Jefferson was mostly clear of snow, and sunshine made for a relatively comfortable round.</p>
                    <br /><br />
                    <p><u>16th, Jackson Park Golf Course, Friday November 27, 2009</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Cassie Phillips</b></td><td class="score"><b>113</b></td><td class="score"><b>-64</b></td><td class="score"><b>49</b></td></tr>
                        <tr><td class="name">Ed Phillips</td><td>102</td><td>-44</td><td>58</td></tr>
                        <tr><td class="name">John Phillips</td><td>106</td><td>-46</td><td>60</td></tr>
                        <tr><td class="name">Keith James</td><td>114</td><td>-41</td><td>73</td></tr>
                        <tr><td class="name">Jeff James</td><td>112</td><td>-37</td><td>75</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>107</td><td>-27</td><td>80</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>127</td><td>-43</td><td>84</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Ed Phillips</b></td><td class="score"><b>52</b></td><td class="score"><b>50</b></td><td class="score"><b>102</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>54</td><td>52</td><td>106</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>58</td><td>49</td><td>107</td></tr>
                        <tr><td class="name">Jeff James</td><td>56</td><td>56</td><td>112</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>59</td><td>54</td><td>113</td></tr>
                        <tr><td class="name">Keith James</td><td>54</td><td>60</td><td>114</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>69</td><td>58</td><td>127</td></tr>
                    </table>
                    <p>Junior Division:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Ethan Phillips</b></td><td class="score"><b>62</b></td><td class="score"><b>55</b></td><td class="score"><b>117</b></td></tr>
                    </table>
                    <br><br>
                    <p><u>15th, West Seattle Golf Course, Friday November 28, 2008</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Keith James</b></td><td class="score"><b>113</b></td><td class="score"><b>-43</b></td><td class="score"><b>70</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td>99</td><td>-28</td><td>71</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>116</td><td>-38</td><td>78</td></tr>
                        <tr><td class="name">Jeff James</td><td>109</td><td>-31</td><td>78</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>136</td><td>-49</td><td>87</td></tr>
                        <tr><td class="name">John Phillips</td><td>118</td><td>-26</td><td>92</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Mark Plummer</b></td><td class="score"><b>50</b></td><td class="score"><b>49</b></td><td class="score"><b>99</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>52</td><td>57</td><td>109</td></tr>
                        <tr><td class="name">Keith James</td><td>56</td><td>57</td><td>113</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>60</td><td>56</td><td>113</td></tr>
                        <tr><td class="name">John Phillips</td><td>58</td><td>60</td><td>118</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>67</td><td>69</td><td>136</td></tr>
                    </table>
                    <p>Junior Division:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Ethan Phillips</b></td><td class="score"><b>52</b></td><td class="score"><b>55</b></td><td class="score"><b>107</b></td></tr>
                    </table>
                    <br><br>
                    <p><u>14th, Jefferson Park Golf Course, Friday November 23, 2007</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>96</b></td><td class="score"><b>-28</b></td><td class="score"><b>68</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td>98</td><td>-23</td><td>75</td></tr>
                        <tr><td class="name">Jeff James</td><td>101</td><td>-22</td><td>79</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>119</td><td>-40</td><td>79</td></tr>
                        <tr><td class="name">Keith James</td><td>113</td><td>-30</td><td>83</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>46</b></td><td class="score"><b>50</b></td><td class="score"><b>96</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td>50</td><td>48</td><td>98</td></tr>
                        <tr><td class="name">Jeff James</td><td>50</td><td>51</td><td>101</td></tr>
                        <tr><td class="name">Keith James</td><td>55</td><td>58</td><td>113</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>61</td><td>58</td><td>119</td></tr>
                    </table>
                    <br><br>
                    <p><u>13th, Bellevue Municipal Golf Course, Friday November 24, 2006</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Keith James</b></td><td class="score"><b>101</b></td><td class="score"><b>-50</b></td><td class="score"><b>51</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>93</td><td>-40</td><td>53</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>94</td><td>-36</td><td>58</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>110</td><td>-50</td><td>60</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>109</td><td>-43</td><td>66</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>121</td><td>-55</td><td>66</td></tr>
                        <tr><td class="name">Peter Thomas</td><td>139</td><td>-60</td><td>79</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>49</b></td><td class="score"><b>44</b></td><td class="score"><b>93</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td></td><td></td><td>94</td></tr>
                        <tr><td class="name">Keith James</td><td>54</td><td>47</td><td>101</td></tr>
                        <tr><td class="name">Ed Phillips</td><td></td><td></td><td>109</td></tr>
                        <tr><td class="name">Bob Phillips</td><td></td><td></td><td>110</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td></td><td></td><td>121</td></tr>
                        <tr><td class="name">Peter Thomas</td><td>75</td><td>64</td><td>139</td></tr>
                    </table>
                    <br><br>
                    <p><u>12th, Twin Rivers Golf Course, Friday November 25, 2005</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>101</b></td><td class="score"><b>-30</b></td><td class="score"><b>71</b></td></tr>
                        <tr><td class="name">Keith James</td><td>105</td><td>-30</td><td>75</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>94</td><td>-17</td><td>77</td></tr>
                        <tr><td class="name">Jeff Ridley</td><td>91</td><td>-8</td><td>83</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>118</td><td>-29</td><td>89</td></tr>
                        <tr><td class="name">Peter Thomas</td><td>131</td><td>-30</td><td>101</td></tr>
                        <tr><td class="name">Lee Bower</td><td>138</td><td>-30</td><td>108</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0">
                        <tr><td class="name"><b>Jeff Ridley</b></td><td class="score"><b>46</b></td><td class="score"><b>45</b></td><td class="score"><b>91</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td>48</td><td>46</td><td>94</td></tr>
                        <tr><td class="name">Jeff James</td><td>49</td><td>52</td><td>101</td></tr>
                        <tr><td class="name">Keith James</td><td>54</td><td>51</td><td>105</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>57</td><td>61</td><td>118</td></tr>
                        <tr><td class="name">Peter Thomas</td><td>75</td><td>56</td><td>131</td></tr>
                        <tr><td class="name">Lee Bower</td><td>67</td><td>71</td><td>138</td></tr>
                    </table>
                    <p class="notes">Rained hard much of the round, and there was a lot of flooding on the course.</p>
                    <br /><br />
                    <p><u>11th, Port Ludlow Golf Club, Friday November 26, 2004</u><br />
                        (Tide and Timber Nines)</p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table15">
                        <tr><td class="name"><b>Cassie Phillips</b></td><td class="score"><b>122</b></td><td class="score"><b>-65</b></td><td class="score"><b>57</b></td></tr>
                        <tr><td class="name">Keith James</td><td>110</td><td>-51</td><td>59</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>106</td><td>-35</td><td>71</td></tr>
                        <tr><td class="name">John Phillips</td><td>114</td><td>-32</td><td>82</td></tr>
                        <tr><td class="name">Jeff James</td><td>114</td><td>-22</td><td>92</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table16">
                        <tr><td class="name"><b>Mark Plummer</b></td><td class="score"><b></b></td><td class="score"><b></b></td><td class="score"><b>106</b></td></tr>
                        <tr><td class="name">Keith James</td><td>52</td><td>58</td><td>110</td></tr>
                        <tr><td class="name">Jeff James</td><td>58</td><td>56</td><td>114</td></tr>
                        <tr><td class="name">John Phillips</td><td>55</td><td>59</td><td>114</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td></td><td></td><td>122</td></tr>
                        <tr><td class="name">Peter Thomas</td><td>77</td><td>80</td><td>157</td></tr>
                        <tr><td class="name">Dorothy Phillips</td></tr>
                        <tr><td class="name">Ralph Phillips</td></tr>
                    </table>
                    <br /><br />
                    <p><u>10th, Jackson Park Golf Course, Friday November 28, 2003</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table17">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>91</b></td><td class="score"><b>-27</b></td><td class="score"><b>64</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>99</td><td>-24</td><td>75</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>103</td><td>-27</td><td>76</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>102</td><td>-23</td><td>79</td></tr>
                        <tr><td class="name">Keith James</td><td>117</td><td>-33</td><td>84</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>132</td><td>-41</td><td>91</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table18">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>42</b></td><td class="score"><b>49</b></td><td class="score"><b>91</b></td></tr>
                        <tr><td class="name">John Phillips</td><td>50</td><td>49</td><td>99</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>52</td><td>50</td><td>102</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>52</td><td>51</td><td>103</td></tr>
                        <tr><td class="name">Keith James</td><td>55</td><td>62</td><td>117</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>68</td><td>64</td><td>132</td></tr>
                    </table>
                    <br /><br />
                    <p><u>9th, Bellevue Municipal Golf Course, Friday November 29, 2002</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table13">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>94</b></td><td class="score"><b>-30</b></td><td class="score"><b>64</b></td></tr>
                        <tr><td class="name">Bob Phillips</td><td>97</td><td>-32</td><td>65</td></tr>
                        <tr><td class="name">Keith James</td><td>103</td><td>-37</td><td>66</td></tr>
                        <tr><td class="name">Tim Murphy</td><td>94</td><td>-27</td><td>67</td></tr>
                        <tr><td class="name">Lee Bower</td><td>108</td><td>-38</td><td>70</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>93</td><td>-20</td><td>73</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>112</td><td>-37</td><td>75</td></tr>
                        <tr><td class="name">Jeff Ridley</td><td>76</td><td>-0</td><td>76</td></tr>
                        <tr><td class="name">Jeff James</td><td>97</td><td>-16</td><td>81</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table14">
                        <tr><td class="name"><b>Jeff Ridley</b></td><td class="score"><b>40</b></td><td class="score"><b>36</b></td><td class="score"><b>76</b></td></tr>
                        <tr><td class="name">Mike Baxter</td><td>43</td><td>44</td><td>87</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>45</td><td>48</td><td>93</td></tr>
                        <tr><td class="name">Tim Murphy</td><td>44</td><td>50</td><td>94</td></tr>
                        <tr><td class="name">John Phillips</td><td>48</td><td>46</td><td>94</td></tr>
                        <tr><td class="name">Jeff James</td><td>45</td><td>52</td><td>97</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>46</td><td>51</td><td>97</td></tr>
                        <tr><td class="name">Darren Chojnocki</td><td>48</td><td>50</td><td>98</td></tr>
                        <tr><td class="name">James Ridley</td><td>49</td><td>51</td><td>100</td></tr>
                        <tr><td class="name">Keith James</td><td>47</td><td>56</td><td>103</td></tr>
                        <tr><td class="name">Lee Bower</td><td>53</td><td>55</td><td>108</td></tr>
                        <tr><td class="name">Cassie Phillips</td><td>53</td><td>59</td><td>112</td></tr>
                    </table>
                    <br /><br />
                    <p><u>8th, Twin Rivers Golf Course, Friday November 23, 2001</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table1">
                        <tr><td class="name"><b>Jeff Ridley</b></td><td class="score"><b>75</b></td><td class="score"><b>-8</b></td><td class="score"><b>67</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>91</td><td>-16</td><td>75</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>93</td><td>-16</td><td>77</td></tr>
                        <tr><td class="name">Keith James</td><td>111</td><td>-29</td><td>82</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table2">
                        <tr><td class="name"><b>Jeff Ridley</b></td><td class="score"><b>38</b></td><td class="score"><b>37</b></td><td class="score"><b>75</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>44</td><td>47</td><td>91</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>51</td><td>42</td><td>93</td></tr>
                        <tr><td class="name">Keith James</td><td>56</td><td>55</td><td>111</td></tr>
                    </table>
                    <br /><br />
                    <p><u>7th, Harbour Pointe Golf Course, Friday November 24, 2000</u></p>
                    <p>Low Net Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table3">
                        <tr><td class="name"><b>Mark Plummer</b></td><td class="score"><b>93</b></td><td class="score"><b>-19</b></td><td class="score"><b>74</b></td></tr>
                        <tr><td class="name">Lee Bower</td><td>115</td><td>-38</td><td>77</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>108</td><td>-31</td><td>77</td></tr>
                        <tr><td class="name">John Phillips</td><td>106</td><td>-29</td><td>77</td></tr>
                        <tr><td class="name">Jeff Ridley</td><td>87</td><td>-9</td><td>78</td></tr>
                        <tr><td class="name">Tim Murphy</td><td>102</td><td>-21</td><td>81</td></tr>
                        <tr><td class="name">Keith James</td><td>119</td><td>-37</td><td>82</td></tr>
                        <tr><td class="name">Jeff James</td><td>106</td><td>-22</td><td>84</td></tr>
                    </table>
                    <p>Low Gross Results:</p>
                    <table cellpadding="0" cellspacing="0" id="Table4">
                        <tr><td class="name"><b>Jeff Ridley</b></td><td class="score"><b>46</b></td><td class="score"><b>41</b></td><td class="score"><b>87</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td>50</td><td>43</td><td>93</td></tr>
                        <tr><td class="name">Tim Murphy</td><td>56</td><td>46</td><td>102</td></tr>
                        <tr><td class="name">Jeff James</td><td>56</td><td>50</td><td>106</td></tr>
                        <tr><td class="name">John Phillips</td><td>49</td><td>57</td><td>106</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>58</td><td>50</td><td>108</td></tr>
                        <tr><td class="name">Lee Bower</td><td>57</td><td>58</td><td>115</td></tr>
                        <tr><td class="name">Keith James</td><td>62</td><td>57</td><td>119</td></tr>
                    </table>
                    <br /><br />
                    <p><u>6th, Snohomish Golf Course, Friday November 26, 1999</u></p>
                    <table cellpadding="0" cellspacing="0" id="Table5">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>89</b></td></tr>
                        <tr><td class="name">Mark Plummer</td><td>102</td></tr>
                        <tr><td class="name">John Phillips</td><td>103</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>106</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>113</td></tr>
                        <tr><td class="name">Keith James</td></tr>
                    </table>
                    <br /><br />
                    <p><u>5th, Lynnwood Golf Course, Friday November 27, 1998</u></p>
                    <table cellpadding="0" cellspacing="0" id="Table6">
                        <tr><td class="name"><b>Tim Murphy</b></td><td class="score"><b>84</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>88</td></tr>
                        <tr><td class="name">Keith James</td><td>95</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>95</td></tr>
                    </table>
                    <br /><br />
                    <p><u>4th, Snoqualmie Falls Golf Course, Friday November 28, 1997</u></p>
                    <table cellpadding="0" cellspacing="0" id="Table7">
                        <tr><td class="name"><b>Jeff Ridley</b></td><td class="score"><b>79</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>96</td></tr>
                        <tr><td class="name">Mark Plummer</td><td>97</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>98</td></tr>
                        <tr><td class="name">Ed Phillips</td><td>98</td></tr>
                        <tr><td class="name">Keith James</td><td>100</td></tr>
                    </table>
                    <br /><br />
                    <p><u>3rd, Lynnwood Golf Course, Friday November 29, 1996</u></p>
                    <table cellpadding="0" cellspacing="0" id="Table8">
                        <tr><td class="name"><b>John Phillips</b></td><td class="score"><b>88</b></td></tr>
                        <tr><td class="name">Jeff James</td><td>88</td></tr>
                        <tr><td class="name">Keith James</td><td>101</td></tr>
                        <tr><td class="name">Bob Phillips</td><td>105</td></tr>
                        <tr><td class="name">Cassie Phillips</td></tr>
                        <tr><td class="name">Dorothy Phillips</td></tr>
                        <tr><td class="name">Ralph Phillips</td></tr>
                        <tr><td class="name">Mark Plummer</td></tr>
                    </table>
                    <br /><br />
                    <p><u>2nd, Kenwanda Golf Course, Friday November 24, 1995</u></p>
                    <table cellpadding="0" cellspacing="0" id="Table9">
                        <tr><td class="name"><b>Jeff James</b></td><td class="score"><b>96</b></td></tr>
                        <tr><td class="name">Keith James</td></tr>
                        <tr><td class="name">Bob Phillips</td></tr>
                        <tr><td class="name">John Phillips</td></tr>
                        <tr><td class="name">Mark Plummer</td></tr>
                    </table>
                    <br /><br />
                    <p><u>1st, Wayne Golf Course, Friday November 25, 1994</u></p>
                    <table cellpadding="0" cellspacing="0" id="Table10">
                        <tr><td class="name"><b>Bob Phillips</b></td></tr>
                        <tr><td class="name">John Phillips</td></tr>
                        <tr><td class="name">Jeff James</td><td class="score">94</td></tr>
                    </table>
                    <br /><br />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import NetScore from './NetScore.vue'
import GrossScore from './GrossScore.vue'

export default {
  name: 'Golf',
  components: {
      NetScore,
      GrossScore
  }
}
</script>

<style>
#golf
{
  max-width: 500px;
  margin: auto
}
table
{
    margin-left: auto;
    margin-right: auto
}
td
{
  text-align: right;
  padding: 0;
  /* border: 1px solid black; */
}
.center
{
    text-align: center
}
.name
{
    width: 130px;
    text-align: left;
}
.score
{
    width: 50px;
}
</style>