<template>
  <div>
    <img src="https://jeffjames.s3.us-west-2.amazonaws.com/brewing.jpg" border="0" alt="Jeff James" />
  </div>
  <div>
    <h2><a href="https://www.jamesbrewing.com" target="_blank">James Brewing</a></h2>
  </div>
  <div>
    <h2><a href="http://dennycreekbrewing.com" target="_blank">Denny Creek Brewing</a></h2>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
