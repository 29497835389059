<template>
  <div>
      <a href="/"><img src="https://jeffjames.s3.us-west-2.amazonaws.com/matia201408.jpg" border="0" alt="Jeff James" /></a>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
